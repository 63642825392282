import { Injectable, inject } from '@angular/core';

import { HttpService } from '@core/services/http.service';
import { UserService } from '@core/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  private readonly http = inject(HttpService);
  private readonly userService = inject(UserService);

  public async deleteTeam(id: number): Promise<any> {
    return await this.http.delete(`api/companies/my/teams/${id}`);
  }

  public async createTeam(name: string): Promise<any> {
    return await this.http.post(`api/companies/my/teams`, { name });
  }

  public async renameTeam(id: number, name: string): Promise<any> {
    return await this.http.put(`api/companies/my/teams/${id}`, { name });
  }

  public getUsersUrl(query?: string, idsToExclude?: number[], idsToInclude?: number[]): string {
    let url = `api/companies/my/users/search?limit=100`;

    if (query) {
      url += `&query=${query}`;
    }

    if (idsToExclude?.length) {
      idsToExclude.forEach((id) => {
        url += `&UserIdsToExclude=${id}`;
      });
    }
    if (idsToInclude?.length) {
      idsToInclude.forEach((id) => {
        url += `&UserIdsToInclude=${id}`;
      });
    }

    return url;
  }

  public async getUsers(query?: string, idsToExclude?: number[], idsToInclude?: number[]): Promise<any> {
    return await this.http.get(this.getUsersUrl(query, idsToExclude, idsToInclude));
  }

  public async removeTeamMembers(teamId: number, contactIds: number[]): Promise<any> {
    const ids = contactIds.map((id) => `contactIds=${id}`).reduce((p, c) => `${p}&${c}`);
    const url = `api/companies/my/teams/${teamId}/members?${ids}`;
    return await this.http.delete(url);
  }

  public async addTeamMembers(teamId: number, contactIds: number[]): Promise<any> {
    return await this.http.post(`api/companies/my/teams/${teamId}/members`, {
      contactIds,
    });
  }

  public async getTeams(offset: number, limit: number): Promise<any> {
    return await this.http.get(`api/companies/my/teams?offset=${offset}&limit=${limit}`);
  }
}
